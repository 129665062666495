/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import UtterancesComments from "../../components/utterances";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    ul: "ul",
    li: "li",
    pre: "pre",
    code: "code",
    h3: "h3",
    h2: "h2",
    blockquote: "blockquote",
    em: "em",
    br: "br",
    strong: "strong",
    hr: "hr"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Introduction"), "\n", React.createElement(_components.p, null, "I was looking for a GDPR compliant and Open Source alternative to Google Analytics, and I found ", React.createElement(_components.a, {
    href: "https://umami.is"
  }, "Umami"), ".\nIt's simple UI is a pleasure to use on both mobile and desktop, which you can check out yourself at ", React.createElement(_components.a, {
    href: "https://app.umami.is/share/8rmHaheU/umami.is"
  }, "Umami's Live Demo"), ". Another big plus is that Umami respects the user's privacy and does not collect identifiable data, which also means I don't have to display that annoying cookie banner. If this sounds like something you'd be interested in, check out the steps below to get it deployed in minutes."), "\n", React.createElement(_components.h1, null, "Login to Azure"), "\n", React.createElement(_components.p, null, "To get started you need the following:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://learn.microsoft.com/en-us/cli/azure/install-azure-cli"
  }, "Azure Cli")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://azure.microsoft.com/en-au/free/"
  }, "Azure Subscription")), "\n"), "\n", React.createElement(_components.p, null, "Before deploying Umami to Azure you need to be logged into your Azure and set it to your subscription.\nOpen a terminal, run the following commands, and follow the interactive login."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "az login\naz account set --subscription <subscriptionId>\n")), "\n", React.createElement(_components.h1, null, "Deploy"), "\n", React.createElement(_components.p, null, "Once logged in, we will run a simple command and follow the prompts to deploy Umami.\nTo run it, ", React.createElement(_components.a, {
    href: "https://github.com/tombrereton/umami-azure"
  }, "clone this repository"), ", navigate to it in terminal and then run Bicep using the following command."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "az deployment sub create --location australiaeast --template-file infra/main.bicep\n")), "\n", React.createElement(_components.h3, null, "Parameters"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "appName"), " e.g. ", React.createElement(_components.code, null, "\"umami\"")), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "env"), " e.g. ", React.createElement(_components.code, null, "2"), " for ", React.createElement(_components.code, null, "\"pdn\"")), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "databasePassword"), " e.g. from ", React.createElement(_components.a, {
    href: "https://bitwarden.com/password-generator/"
  }, "password-generator")), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "hashSalt"), " e.g. from ", React.createElement(_components.a, {
    href: "https://bitwarden.com/password-generator/"
  }, "password-generator")), "\n"), "\n", React.createElement(_components.h3, null, "Resource Group"), "\n", React.createElement(_components.p, null, "The Bicep file will deploy all the resources to a resource group called, ", React.createElement(_components.code, null, "rg-[appName]-[env]")), "\n", React.createElement(_components.h2, null, "Password and Hash Salt"), "\n", React.createElement(_components.p, null, "The command will prompt you to enter a few parameters.\nWhen prompted for the ", React.createElement(_components.code, null, "databasePassword"), " and ", React.createElement(_components.code, null, "hashSalt"), ", I recommend\nstoring them somewhere secure like a password manager."), "\n", React.createElement(_components.p, null, "You can use the ", React.createElement(_components.code, null, "databasePassword"), " you entered, with the sqlserver username ", React.createElement(_components.code, null, "\"umami\""), " to login\ninto the database directly. If you want to change the username, edit it in the\n", React.createElement(_components.code, null, "mysql.bicep"), " file."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Note that you do ", React.createElement(_components.em, null, "not"), " need to record the ", React.createElement(_components.code, null, "databasePassword"), " and ", React.createElement(_components.code, null, "hashSalt"), " to login to Umami, only to login to the database or to point a new Umami app at an existing database."), "\n"), "\n", React.createElement(_components.p, null, "Note that a ", React.createElement(_components.a, {
    href: "https://auth0.com/blog/adding-salt-to-hashing-a-better-way-to-store-passwords/"
  }, "hash salt"), " is used when hashing passwords, to make it more secure."), "\n", React.createElement(_components.p, null, "You can use any random string for the password and hash salt, but it is recommended to use something like the following: ", React.createElement(_components.a, {
    href: "https://bitwarden.com/password-generator/"
  }, "bitwarden.com/password-generator")), "\n", React.createElement(_components.h1, null, "Different Location"), "\n", React.createElement(_components.p, null, "You can change the ", React.createElement(_components.code, null, "--location"), " in the ", React.createElement(_components.code, null, "az deployment"), " command to somewhere closer.", React.createElement(_components.br), "\n", "Run the following command and use one of the values from the ", React.createElement(_components.code, null, "Name"), " column."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "az account list-locations -o table\n")), "\n", React.createElement(_components.h1, null, "Important"), "\n", React.createElement(_components.p, null, "We are using ", React.createElement(_components.a, {
    href: "https://learn.microsoft.com/en-us/azure/container-apps/overview"
  }, "Azure Container Apps"), " (ACA) to host Umami. ACA are serverless containers which scale down to zero (saving you money),\nbut this also means they are slow to load the first time if they have been inactive for a while. So if it's taking\na while to load, don't panic! Give it 10-20 seconds to spin up and then you can login."), "\n", React.createElement(_components.h1, null, "Azure Resources"), "\n", React.createElement(_components.p, null, "The Bicep files deploy the following main resources:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://learn.microsoft.com/en-us/azure/container-apps/overview"
  }, "Azure Container App")), "\n", React.createElement(_components.li, null, "MySql Server and Database"), "\n"), "\n", React.createElement(_components.p, null, "It also deploys the supporting resources for the Azure Container App:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Container App Environment"), "\n", React.createElement(_components.li, null, "Log Analytics Workspace"), "\n"), "\n", React.createElement(_components.h1, null, "Next Steps"), "\n", React.createElement(_components.p, null, "Follow the ", React.createElement(_components.a, {
    href: "https://umami.is/docs/login"
  }, "official Umami documentation"), " from the ", React.createElement(_components.code, null, "Login"), " stage!"), "\n", React.createElement(_components.p, null, "To get the url for the login either navigate to the ACA in the Azure Portal or run the following\ncommand in the terminal."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, " az deployment group show \\\n  -g \"rg-umami-pdn\" \\\n  -n \"aca-umami\" \\\n  --query properties.outputs.fqdn.value\n")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Your Umami installation will create a default administrator account with the username ", React.createElement(_components.strong, null, "admin"), " and the password ", React.createElement(_components.strong, null, "umami"), "."), "\n", React.createElement(_components.p, null, "The first thing you will want to do is log in and change your password."), "\n"), "\n", React.createElement(_components.h1, null, "Bonus: Custom Domain"), "\n", React.createElement(_components.p, null, "You can add a custom domain to your new Umami Admin Website by following the ", React.createElement(_components.a, {
    href: "https://learn.microsoft.com/en-us/azure/container-apps/custom-domains-certificates"
  }, "Custom Domain Certificate Documentation"), " for Azure Container Apps."), "\n", React.createElement(_components.hr), "\n", React.createElement(UtterancesComments));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
